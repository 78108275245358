import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { Box, Button, Checkbox, Text, VStack } from '@chakra-ui/react';

const RelationshipDropdown = ({ relationships, selectedRelationships, setSelectedRelationships }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (name) => {
    setSelectedRelationships(prev =>
      prev.includes(name)
        ? prev.filter(item => item !== name)
        : [...prev, name]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        onClick={toggleDropdown}
        bg="black"
        border="1px"
        borderColor="#69b3c1"
        rounded="md"
        px={4}
        py={2}
        fontSize="sm"
        fontWeight="medium"
        color="#69b3c1"
        _hover={{ bg: '#69b3c1', color: 'black' }}
        _focus={{ outline: 'none', ring: 2, ringOffset: 2, ringColor: '#69b3c1' }}
        w="full"
        rightIcon={<ChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />}
      >
        Select Relationships
      </Button>
      {isOpen && (
        <Box
          position="absolute"
          zIndex={10}
          mt={1}
          w="full"
          bg="black"
          boxShadow="lg"
          maxH="60"
          borderRadius="md"
          py={1}
          overflowY="auto"
          border="1px"
          borderColor="#69b3c1"
        >
          <VStack align="start" spacing={1} px={4} py={2}>
            {Object.entries(relationships).map(([name, relation]) => (
              <Checkbox
                key={name}
                isChecked={selectedRelationships.includes(name)}
                onChange={() => handleCheckboxChange(name)}
                colorScheme="teal"
                size="lg"
                iconColor="#69b3c1" 
              >
                <Text fontSize="sm" color="#69b3c1">
                  {name} ({relation})
                </Text>
              </Checkbox>
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default RelationshipDropdown;
