import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import arislogo from './assests/aris-logo-cut.png';
import logout from './assests/logout.png'
import { position } from '@chakra-ui/react';

const Homepage = ({ username, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = (event) => {
      // Check if we're currently on the homepage
      if (location.pathname === '/homepage') {
        // Prevent the default back action
        event.preventDefault();
        // Navigate to the root page
        navigate('/', { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location]);

  const buttonVariants = {
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    overflow: 'hidden',
  };
  
  const logoutButtonStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '35px',
    height: '35px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  };
  
  const logoStyle = {
    width: '80px',
    height: '80px',
    marginBottom: '2rem',
  };
  
  const titleStyle = {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    fontWeight: 'bold',
    marginBottom: '3rem',
    color: '#69B3C1',
    textAlign: 'center',
  };
  
  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    width: '80%',
    maxWidth: '300px',
  };
  
  const buttonBaseStyle = {
    padding: '1rem',
    fontSize: 'clamp(1rem, 3vw, 1.125rem)',
    fontWeight: '600',
    borderRadius: '9999px',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    width: '100%',
  };
  
  const speechButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: '#69B3C1',
    color: 'black',
  };
  
  const textButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: 'black',
    color: '#69B3C1',
    border: '2px solid #69B3C1',
  };
  return (
    <div style={containerStyle}>
      <img src={logout} alt="Logout" style={logoutButtonStyle} onClick={onLogout} />
      <img src={arislogo} alt="Aris logo" style={logoStyle} />
      <motion.h1
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        style={titleStyle}
      >
        Welcome to Aris
      </motion.h1>
      <div style={buttonContainerStyle}>
        <motion.button
          variants={buttonVariants}
          whileHover="hover"
          style={speechButtonStyle}
          onClick={() => navigate('/speech-assistant')}
        >
          Aris voice
        </motion.button>
        <motion.button
          variants={buttonVariants}
          whileHover="hover"
          style={textButtonStyle}
          onClick={() => navigate('/speech-assistant-2')}
        >
          Aris voice-2
        </motion.button>
        <motion.button
          variants={buttonVariants}
          whileHover="hover"
          style={speechButtonStyle}
          onClick={() => navigate('/text-assistant')}
        >
          Aris chat
        </motion.button>
       
      </div>
    </div>
  );
};

export default Homepage;