import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useUser } from './UserContext.js';

import { ChakraProvider, Box, Button, Text, Heading, Center, VStack, useColorMode, Image, Flex, Input } from '@chakra-ui/react';
import logo from './assests/aris-logo-cut.png';
import ReactMarkdown from 'react-markdown';
import loading from './assests/chat-loading.gif';
import arrowUpIcon from './assests/arrowup.png';
import arrowDownIcon from './assests/arrowdown.png';
import { useNavigate } from 'react-router-dom';
import RelationshipDropdown from './relationshipdropdown.js';


const SpeechApp = ({ onLogout }) => {
  // const location = useLocation();
  // const username = location.state?.username || 'userr';
  // const { username } = useUser();
  const [relations, setRelations] = useState([]);
  const { user } = useUser();
  const navigate = useNavigate();
  const [relationships, setRelationships] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [responseText, setResponseText] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [chatHistoryList, setChatHistoryList] = useState([]); //{"role":"system","content":`Hi ${username}, I am Aris your personal life coach how can I assist you today?`}
  const [chatContainer, setChatContainer] = useState([]);
  const [showFixedNav, setShowFixedNav] = useState(false);
  const [isNewSession, setIsNewSession] = useState(false);
  const [isArrowUp, setIsArrowUp] = useState(true);
  const [showArrow, setShowArrow] = useState(false);
  const [apiChatHistory, setApiChatHistory] = useState([]);
  const[showNewSession,setNewSession]=useState(false);
 
  const [hasResponse, setHasResponse] = useState(false);
  const [selectedRelationships, setSelectedRelationships] = useState([]);
  
  const fullText = `Hi ${user}, I am Aris your personal life coach how can I assist you today?`;
  const [displayedText, setDisplayedText] = useState('');

  const buttonStyle = {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: 50,
    padding: '8px',
    borderRadius: '50%',
    backgroundColor: '#e5e7eb',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  };

  const hoverStyle = {
    backgroundColor: '#d1d5db',
  };

  const [isHovered, setIsHovered] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

      setIsScrolled(scrolled);
      setShowFixedNav(scrolled && chatContainer.length > 1);
      setShowArrow(scrolled);
      setIsArrowUp(!isAtBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [chatContainer.length]);

  const handleArrowClick = () => {
    if (isArrowUp) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  };
  useEffect(() => {
    fetchRelationships(user);
  }, [user]);

  useEffect(() => {
    let currentIndex = 0;
  
    const intervalId = setInterval(() => {
      if (currentIndex < fullText.length) {
        const char = fullText[currentIndex];
        if (char !== undefined && char !== null) {
          setDisplayedText((prev) => prev + char);
          currentIndex++;
        }
      } else {
        clearInterval(intervalId);
      }
    }, 40);
  
    return () => clearInterval(intervalId);
  }, [fullText]);
  
  useEffect(() => {
    if (isNewSession) {
      // {"role":"system","content":`Hi ${username}, I am Aris your personal life coach how can I assist you today?`}
      setChatHistoryList([]);
      setDisplayedText('');
      setChatContainer([]);
      setIsNewSession(false);
    }
  }, [isNewSession]);

  const handleNewSession = () => {
    setIsNewSession(true);
    setChatHistoryList(false);
    setDisplayedText('');
    setResponseText('');
    setUserInput('');
    setChatContainer([]);
    setNewSession(false);
  };


  const fetchRelationships = async (userName) => {
    try {
      const response = await fetch(`https://backend-aris-sp.goml.io/ARIS/relationship?name=${encodeURIComponent(userName)}&type=list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const lowercaseData = data.map(item => item.toLowerCase());
    
      setRelationships(lowercaseData);
     
    } catch (error) {
      console.error("Error fetching relationships:", error);
      setRelationships([]);
      return [];
    }
  };
  
  useEffect(() => {
    fetchRelationships(user);
  }, [user]);


  const sendTextToChatbotApi = async (text) => {
    try {

      console.log(relationships);
      const matchingRelationships = relationships.filter(name => 
        text.toLowerCase().includes(name.toLowerCase())
      );
  
      setChatContainer((prev) => [
        ...prev,
        { user: text, system: 'loading' },
      ]);
      console.log("🔥",matchingRelationships,"🔥");
      const capitalizedRelationships = matchingRelationships.map(relation => 
        relation.charAt(0).toUpperCase() + relation.slice(1)
      );
  
      // Assuming there's a state or variable holding the previous relations list
      // Let's call it previousRelations
      const updatedRelations = await new Promise(resolve => {
        setRelations(prevRelations => {
          const uniqueNewRelations = capitalizedRelationships.filter(
            relation => !prevRelations.includes(relation)
          );
          const newRelations = [...prevRelations, ...uniqueNewRelations];
          resolve(newRelations);
          return newRelations;
        });
      });
      const controller = new AbortController();
      const signal = controller.signal;
      const url = 'https://backend-aris-sp.goml.io/ARIS/new_chat';

  
      const requestBody = {
        query: text,
        name: user,
        chat_history: chatHistoryList.length === 1 && chatHistoryList[0].role === 'system' 
          ? [] 
          : chatHistoryList,
        relations: updatedRelations// Set to null if no value is passed
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let data = '';
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        data += decoder.decode(value);
        setChatContainer((prev) =>
          prev.map((item, index) =>
            index === prev.length - 1 ? { ...item, system: data } : item
          )
        );
      }
  
      setChatHistoryList((prevChatHistory) => [
        ...prevChatHistory,
        { role: "user", content: text },
        { role: "system", content: data },
      ]);
  
      setApiChatHistory((prevApiChatHistory) => [...prevApiChatHistory, text, data]);
  
      setHasResponse(true);
      setNewSession(true);
    } catch (error) {
      setChatContainer((prev) =>
        prev.map((item, index) =>
          index === prev.length - 1 ? { ...item, system: 'Error: Could not get response' } : item
        )
      );
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.trim()) {
      sendTextToChatbotApi(userInput);
      setUserInput('');
    }
  };

  const handleChange = (event) => {
    const value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedRelationships(value);
  };

  useColorMode('dark');
  
  return (
    <ChakraProvider>
    <Box
      bg="black"
      minHeight="100vh"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {/* Back button */}
      <Box position="absolute" top="23px" left="25px" zIndex={101}>
        <Button
          size="sm"
          color="white"
          backgroundColor="#69B3C1"
          onClick={() => navigate('/homepage')}
        >
          Back
        </Button>
      </Box>

      {/* Header */}
      <Box bg="black" p={4} position={showFixedNav ? "fixed" : "static"} top={0} left={0} right={0} zIndex={100}>
        <Flex alignItems="center" justifyContent="center">
          <Image
            src={logo}
            alt="Logo"
            w={["40px", "50px", "60px"]}
            h="auto"
            mr={3}
          />
          <Heading
            as="h1"
            size={["lg", "xl", "2xl"]}
            color="white"
          >
            Aris
          </Heading>
        </Flex>
      </Box>

      {/* Welcome text */}
      <Box mt={showFixedNav ? 20 : 0}>
        <VStack spacing={4} mt={8}>
          <Heading fontSize={['md', 'lg', 'xl']} mt={12} mb={20} textAlign="center">{displayedText}</Heading>
        </VStack>
      </Box>

      {/* Chat messages */}
      <VStack spacing={8} px={[2, 4, 6]} py={8} align="stretch" mb={20}>
        {chatContainer.map((chat, index) => (
          <Box key={index}>
            {/* User message */}
            <Box display="flex" justifyContent="flex-end" mb={6}>
              <Box maxW={["90%", "80%", "70%"]}>
                <Text fontSize="sm" fontWeight="bold" mb={2} textAlign="right" color="white">
                  You:
                </Text>
                <Box
                  bg="#69B3C1"
                  p={3}
                  borderTopRadius="xl"
                  borderBottomLeftRadius="xl"
                  boxShadow="md"
                  color="white"
                  overflowWrap="break-word"
                  display="inline-block"
                >
                  <Text fontSize="sm">
                    <ReactMarkdown>{chat.user}</ReactMarkdown>
                  </Text>
                </Box>
              </Box>
            </Box>

            {/* AI response */}
            <Box
              display="flex"
              justifyContent="flex-start"
              mb={6}
              position="relative"
            >
              <Image
                src={logo}
                alt="Logo"
                w="40px"
                h="40px"
                position="absolute"
                top="0px"
                left="-20px"
                borderRadius="50%"
                boxShadow="0 0 10px rgba(0,0,0,0.5)"
                display={["none", "block"]}
              />
              
              {chat.system === 'loading' ? (
                <Image src={loading} alt="Loading..." w="40px" h="40px" ml="30px" />
              ) : (
                <Box
                  maxW={["90%", "80%", "70%"]}
                  ml={["0", "40px"]}
                  bg="black"
                  p={3}
                  borderRadius="xl"
                  boxShadow="md"
                  border="2px solid"
                  borderColor="#69B3C1"
                  overflowWrap="break-word"
                  display="inline-block"
                >
                  <Text fontSize="sm" color="white">
                    <ReactMarkdown>{chat.system}</ReactMarkdown>
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </VStack>

      {/* Input form */}
      <Box p={4} bg="black" position="fixed" bottom={0} left={0} right={0} zIndex={50} >
        <Center>
          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '600px', backgroundColor: 'black', }}>
            <Flex>
              <Input
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type your message..."
                bg="white"
                color="black"
                mr={2}
                size="md"
                borderRadius='5px'
              />
              <Button type="submit" bg="#69B3C1" color="white" size="md">
                Send
              </Button>
            </Flex>
          </form>
        </Center>
      </Box>

      {/* New Session button */}
      {hasResponse && showNewSession&&(
        <Center position="fixed" bottom="60px" left={0} right={0} mb='10px' zIndex={51}>
          <Button 
            width="auto"
            minWidth="100px"
            bg="#69B3C1" 
            color="white" 
            onClick={handleNewSession}
            _hover={{ bg: "#69B3C1", color: "black" }}
            size="sm"
           
          >
            New Session
          </Button>
        </Center>
      )}

      {/* Scroll arrow */}
      {showArrow && showFixedNav && (
        <Button
          onClick={handleArrowClick}
          position="fixed"
          bottom="80px"
          right="16px"
          zIndex={50}
          borderRadius="full"
          bg="#e5e7eb"
          _hover={{ bg: "#d1d5db" }}
          size="sm"
          display={{ base: "none", md: "block" }}
        >
          <Image 
            src={isArrowUp ? arrowUpIcon : arrowDownIcon} 
            alt={isArrowUp ? "Scroll to top" : "Scroll to bottom"} 
            w="20px"
            h="20px"
          />
        </Button>
      )}
    </Box>
  </ChakraProvider>
  );
};

export default SpeechApp;