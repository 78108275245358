import React, { useEffect, useRef, useCallback, useState } from "react";
import { RealtimeClient } from "@openai/realtime-api-beta";
import { WavRecorder, WavStreamPlayer } from "./lib/wavtools/index.js";
import { getInstructions } from "./utils/conversation_config.js";
import { WavRenderer } from "./utils/wav_renderer.ts";
import { X, Mic, PhoneOff } from "react-feather";
import staticImage from './assests/aris-gif-pic.png';
import gifImage from './assests/SVKl.gif';
import { useUser } from './UserContext.js';
import arisLogo from './assests/aris-logo-cut.png';
import './css/speech.css';
import { position } from "@chakra-ui/react";
import voiceImg from './assests/voice.png';
import { useNavigate } from 'react-router-dom';



const SpeechRecognitionApp = ({ onLogout }) => {


  
  const navigate = useNavigate();
 

  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  if (apiKey !== "") {
    localStorage.setItem("tmp::voice_api_key", apiKey);
  }
  const { user } = useUser();
  const{promptInstruction}=useUser();

  const [turnEndType, setTurnEndType] = useState("none");
  const wavRecorderRef = useRef(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef(
    new RealtimeClient({
      apiKey: apiKey,
      dangerouslyAllowAPIKeyInBrowser: true
    })
  );
  

  const [items, setItems] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [instruct,setInstruct] =  useState(null);
  const [selectedVoice, setSelectedVoice] = useState("echo");
  const [error, setError] = useState(null);
  const [relationshipsofspeech, setRelationshipsforspeech] = useState({});
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [instructions, setInstructions] = useState(null);

  const[isapiCalled,setisapiCalled]=useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const[disconnected,setdisconnect]=useState(false);
  const[loading,setisloading]=useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const showTemporaryAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000); // Hide after 3 seconds
  };

  const getButtonWidth = () => {
    if (windowWidth >= 1024) return '288px'; // lg
    if (windowWidth >= 768) return '256px';  // md
    return '100%';                           // mobile
  };

  const getFontSize = () => {
    return windowWidth >= 768 ? '16px' : '14px';
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: getButtonWidth(),
    padding: '12px 16px',
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: isRecording ? '#ff4136' : '#6cb4c2',
    color: 'black',
    opacity: (!isConnected || !canPushToTalk) ? '0.5' : '1',
    fontSize: getFontSize(),
    transition: 'all 0.2s ease',
    userSelect: 'none',         // Add this
  WebkitUserSelect: 'none',   // Add this for Safari
  MozUserSelect: 'none',      // Add this for Firefox
  msUserSelect: 'none',       // Add this for IE/Edge
  WebkitTouchCallout: 'none'


  };

  const iconStyle = {
    width: '16px',
    height: '16px'
  };
  const textStyle = {
    pointerEvents: 'none', // Prevents text selection
    userSelect: 'none',         // Add this
    WebkitUserSelect: 'none',   // Add this for Safari
    MozUserSelect: 'none',      // Add this for Firefox
    msUserSelect: 'none'   
  };

  const cleanupResources = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    try {
      if (client.isConnected()) {
        client.disconnect();
      }
      await wavRecorder.end();
      await wavStreamPlayer.interrupt();
      setIsConnected(false);
      setdisconnect(false);
     
      setItems([]);
    } catch (error) {
      console.error("Error cleaning up resources:", error);
    }
  }, []);


//   const callApi = () => {
//     const currentItems = itemsRef.current;
//     fetch('https://backend-aris-sp.goml.io/ARIS/history_summary', {
//         method: 'POST',
//         headers: {
//             'accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             name: user,
//             history: currentItems
//         })
//     })
//     .catch(error => console.error("Error calling API:", error));
// };

  const itemsRef = useRef(items);

// Update the ref whenever items change
useEffect(() => {
  // console.log("👻👻👻👻",items)
  itemsRef.current = items;
}, [items]);

useEffect(() => {
  window.history.replaceState({ page: 'current' }, '', window.location.href);
  // Event listeners for browser back button, reload, and tab close
  const handleBeforeUnload = (event) => {
    if (!disconnected) {  // Check if disconnect is false
    //  callApi();
  }
      event.preventDefault();
      event.returnValue = ''; // Required for most browsers
  };

  const handlePopState = (event) => {
    if (event.state && event.state.page === 'current' && !disconnected) {
     // callApi();
    }
  };

  // Set up event listeners
  window.addEventListener('beforeunload', handleBeforeUnload);  // Reload or tab close
  window.addEventListener('popstate', handlePopState);  // Browser back button

  // Cleanup listeners on component unmount
  return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
  };
}, [disconnected]);

const handleBackButtonClick = () => {
  if(disconnected){
    navigate('/homepage');
  }
  else{
   // callApi();
    navigate('/homepage');
  }
  
 
};
  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // ... (previous event listeners and setup code)

    // Add a new event listener for beforeunload
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      cleanupResources();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      cleanupResources();
    };
  }, [cleanupResources]);
 
  const connectConversation = useCallback(async () => {
    setIsConnected(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    //client.updateSession({tool_choice:'required'});
    //setdisconnect(false);
    setisloading(true);
    
    client.addTool(
      {
        "type": "function",
       "name": 'get_user_prism',
       "description": 'Gets a list of recommended chunks of personal information based on a name and query input.',
       "parameters": {
         "type": 'object',
         "properties": {
           "name": {
             "type": 'array',
             "items": {
                 "type": "string"
             },
             "description": 'The name of the person in the query',
           },
           "query": {
             "type": 'string',
             "description": 'The query to be searched in the vector base, provided by the user.',
           },
         },
         "required": ['name', 'query'],
       },
     },
     async ({ name, query }) => {
      const result = await fetch(
          'https://backend-aris-sp.goml.io/ARIS/get_prism_topic',
          {
              method: 'POST',
              headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  names: name,
                  query: query
              })
          }
      );
      const json = await result.json();
      return json;
  }
    );
    
    
    setItems([]);

    try {
      await wavRecorder.begin();
      await wavStreamPlayer.connect();
      await client.connect();
      var result_query = await fetch(
        `https://backend-aris-sp.goml.io/ARIS/history_query?name=${encodeURIComponent(user)}`, // Use template literals and encode user
        {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: user
            })
        }
    
    );
    var json_qurey = await result_query.json();
    console.log("🐺🐺🐺🐺🐺🐺🐺🐺🐺",json_qurey)
      client.sendUserMessageContent([
        {
          type: "input_text",
          text: json_qurey
        }
      ]);

      if (client.getTurnDetectionType() === "server_vad") {
        await wavRecorder.record(data => client.appendInputAudio(data.mono));
      }
      setisloading(false);
     
    } catch (error) {
      console.error("Error connecting conversation:", error);
      setError("Failed to connect conversation. Please try again.");
      setIsConnected(false);
    }
  }, []);

  const disconnectConversation = useCallback(async () => {
    setisloading(false);
  //   const currentItems = itemsRef.current;
  //   var result = fetch(
  //     'https://backend-aris-sp.goml.io/ARIS/history_summary',
  //     {
  //         method: 'POST',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //             // eslint-disable-next-line no-restricted-globals
  //             name: user,
  //             history: currentItems
  //         })
  //     }
  // );
  //callApi();
  setdisconnect(true);
    setIsConnected(false);
    setItems([]);
     
 
   
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
  

    try {
      
    setisapiCalled(false);
      client.disconnect();
      client.removeTool("get_user_prism");
      await wavRecorder.end();
      await wavStreamPlayer.interrupt();
    } catch (error) {
      console.error("Error disconnecting conversation:", error);
      setError("Failed to disconnect conversation. Please try again.");
    }
  }, []);


  const deleteConversationItem = useCallback(async id => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);
  const recordingStateRef = useRef(false);
  const startRecording = async () => {
    try {
      if (recordingStateRef.current) {
        showTemporaryAlert('Recording is already in progress. Please wait.');
        return;
      }
    
    setIsRecording(true);
    recordingStateRef.current = true;
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record(data => client.appendInputAudio(data.mono));
  }catch (error) {
    console.error('Recording error:', error);
    showTemporaryAlert('Unable to start recording. Please try again.');
    setIsRecording(false);
    recordingStateRef.current = false;
  }
  };

  const stopRecording = async () => {
    try {
      if (!recordingStateRef.current) {
        showTemporaryAlert('No active recording to stop.');
        return;
      }

    setIsRecording(false);
    recordingStateRef.current = false;
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  } catch (error) {
    console.error('Stop recording error:', error);
    showTemporaryAlert('Error stopping recording. Please try again.');
  }
  };

  const handleToggleChange = (event) => {
    changeTurnEndType(event.target.value);
    setTurnEndType(event.target.value);
  };


  const changeTurnEndType = async value => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === "none" && wavRecorder.getStatus() === "recording") {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === "none" ? null : { type: "server_vad" }
    });
    if (value === "server_vad" && client.isConnected()) {
      await wavRecorder.record(data => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === "none");
  };


  // const changeTurnEndType = async value => {
  //   const client = clientRef.current;
  //   const wavRecorder = wavRecorderRef.current;
  //   if (value === "none" && wavRecorder.getStatus() === "recording") {
  //     await wavRecorder.pause();
  //   }
  //   client.updateSession({
  //     turn_detection: value === "none" ? null : { type: "server_vad" }
  //   });
  //   if (value === "server_vad" && client.isConnected()) {
  //     await wavRecorder.record(data => client.appendInputAudio(data.mono));
  //   }
  //   setCanPushToTalk(value === "none");
  // };

  const handleVoiceChange = async (event) => {
    const newVoice = event.target.value;
    
    try {
      if (isConnected) {
        // Disconnect the current conversation
        await disconnectConversation();
      }

      // Update the voice
      setSelectedVoice(newVoice);
      await clientRef.current.updateSession({ voice: newVoice });

      if (isConnected) {
        // Reconnect the conversation with the new voice
        await connectConversation();
      }
    } catch (error) {
      console.error("Error changing voice:", error);
      setError("Failed to change voice. Please try again.");
    }
  };


  const fetchRelationshipsforspeech = async (userName) => {
    try {
      const response = await fetch(`https://backend-aris-sp.goml.io/ARIS/relationship?name=${encodeURIComponent(userName)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setRelationshipsforspeech(data.relationship);
     
    } catch (error) {
      console.error("Error fetching relationships:", error);
      setRelationshipsforspeech({});
      return [];
    }
  };

  useEffect(() => {
    fetchRelationshipsforspeech(user);
  }, [user]);
  const handleRelationshipChange = async (event) => {
    // console.log("🦄🦄",event.target.value);
    const newRelation = event.target.value;
    setSelectedRelationship(newRelation);
    
    try {
      const newInstructions = await getInstructions(user, newRelation);
      setInstructions(newInstructions);
      
      // Update the client's session with the new instructions
      clientRef.current.updateSession({ instructions: newInstructions });
    } catch (error) {
      console.error("Error updating instructions:", error);
      setError("Failed to update instructions. Please try again.");
    }
  };





  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

if(instructions){
  client.updateSession({ instructions: instructions });

}else{
  client.updateSession({ instructions: promptInstruction });
}

 
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });
    
    client.updateSession({voice:selectedVoice});

    client.on("realtime.event", realtimeEvent => {
    //  console.log("Realtime event:", realtimeEvent);
    });
    client.on("error", event => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on("conversation.updated", async ({ item, delta }) => {
      // console.log("🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟")
      var items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      items = items
    .filter(item => item.type === 'message') // Step 1: Keep only items where type is 'message'
    .map(item => {
        if (item.role === 'user' || item.role === 'assistant') {
            return {
                role: item.role,
                message: item.formatted.transcript // Step 2: Format the message for 'user' and 'assistant' roles
            };
        }
        return null; // Return null for items that don’t match 'user' or 'assistant' roles
    })
    .filter(Boolean);

      setItems(items);



      const currentItems = itemsRef.current;
      fetch('https://backend-aris-sp.goml.io/ARIS/history_summary', {
          method: 'POST',
          headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              name: user,
              history: currentItems
          })
      })
      .catch(error => console.error("Error calling API:", error));




    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, [selectedVoice]);

  return   (
    <div className="page-container" style={{ position: 'relative', minHeight: '100vh' }}>
    <div className="content-container" style={{ paddingTop: '20px' }}>
      {/* Back button */}
      <div style={{ position: 'absolute', top: '23px', left: '25px'}}>
        <button
          size={30} 
          color="#6cb4c2" 
          onClick={handleBackButtonClick} 
          style={{ cursor: 'pointer' ,width:'50px',height:'27px',backgroundColor:'black',color:'#69b3c1',border:'1.2px solid #69b3c1',borderRadius:'5px'}}
        >Back</button>
      </div>

{!isConnected &&(
     
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '20px', right: '20px' }}>
        <img src={voiceImg} width={40} height={40} alt="Voice" />
        <select
          value={selectedVoice}
          onChange={handleVoiceChange}
          style={{
            padding: '5px',
            borderRadius: '5px',
            backgroundColor: 'black',
            color: '#6cb4c2',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            marginLeft: '10px',
          }}
        >
          <option value="alloy">Alloy</option>
          <option value="echo">Echo</option>
        
          <option value="shimmer">Shimmer</option>
        </select>
      </div>
)}

      {/* Logo and title */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <img 
          src={arisLogo} 
          width="70px" 
          height="70px" 
          alt="Aris Logo" 
          style={{ marginRight: '10px', cursor: 'pointer' }} 
          onClick={() => navigate('/homepage')} 
        />
        <h1 style={{ margin: 0, cursor: 'pointer' }} onClick={() => navigate('/homepage')}>
          Aris Voice
        </h1>
      </div>

      {/* Status image */}
      <img
        src={isConnected ? gifImage : staticImage}
        alt="App status"
        className="status-image"
        onClick={isConnected ? disconnectConversation : connectConversation}
        style={{ display: 'block', margin: '0 auto', cursor: 'pointer' }}
      />
      <h4 style={{ color: 'white', textAlign: 'center', marginTop: '30px',marginBottom:'20px' }}>
        {isConnected ? "Tap to disconnect" : "Tap to connect"}
      </h4>
      <h4 style={{ color: 'white', textAlign: 'center', marginTop: '30px',marginBottom:'20px' }}>
      {loading && (
         <h3 style={{ color: 'white', textAlign: 'center',marginBottom:'10px' }}>
         Your session is being created...
       </h3>
      )}
      </h4>
   
{!isConnected&&(
    <select 
          value={turnEndType} 
          onChange={handleToggleChange}
          style={{ 
            backgroundColor: 'black', 
            color: '#6cb4c2', 
            padding: '10px', 
            border: '1px solid #6cb4c2',
            borderRadius: '5px',
            marginBottom: '20px',
            marginTop:'20px',
            width: '20%'
          }}
        >
          <option value="none">Manual</option>
          <option value="server_vad">VAD</option>
        </select>

)}
        {isConnected && canPushToTalk && !loading && (
          <button
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onTouchStart={(e) => {
              e.preventDefault(); // Prevents default touch behavior
              startRecording(e);
            }}
            onTouchEnd={(e) => {
              e.preventDefault(); // Prevents default touch behavior
              stopRecording(e);
            }}

            disabled={!isConnected || !canPushToTalk}
            style={buttonStyle}
          >
                <Mic style={iconStyle} />
      <span style={textStyle}>
        {isRecording ? "Release to Send" : "Press and hold to Talk"}
      </span>
          </button>

          )}
           </div>
    <ul className="circles" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', margin: 0, padding: 0 }}>
      {[...Array(10)].map((_, index) => (
        <li key={index}></li>
      ))}
    </ul>
  </div>
  );
};

export default SpeechRecognitionApp;