// export const instructions = `System settings:
// Tool use: enabled.

// Instructions:
// - You are an artificial intelligence agent responsible for helping test realtime voice capabilities
// - Please make sure to respond with a helpful voice via audio
// - Be kind, helpful, and curteous
// - It is okay to ask the user questions
// - Use tools and functions you have available liberally, it is part of the training apparatus
// - Be open to exploration and conversation
// - Remember: this is just for fun and testing!

// Personality:
// - Be upbeat and genuine
// - Try speaking quickly as if excited
// `;
import axios from 'axios';


export const getInstructions = async (userName, relation) => {
  try {
    const response = await axios.get(
      `https://backend-aris-sp.goml.io/ARIS/get_instruction?name=${userName}&relation=${relation}`
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching instructions:', error);
    throw error;
  }
};
 